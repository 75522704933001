import {createStore, combineReducers, compose} from 'redux';
import persistState from 'redux-localstorage';

function tokenReducer(state = '', action) {
    switch (action.type) {
        case 'SET_TOKEN':
            return action.token;
        case 'CLEAR_TOKEN':
            return '';
        default:
            return state;
    }
}

function refreshTokenReducer(state = '', action) {
    switch (action.type) {
        case 'SET_REFRESH_TOKEN':
            return action.refresh;
        case 'CLEAR_REFRESH_TOKEN':
            return '';
        default:
            return state;
    }
}

function userReducer(state = null, action) {
    switch (action.type) {
        case 'LOGGED_IN':
            return action.user;
        case 'SIGN_OUT':
            return null;
        default:
            return state;
    }
}

let rootReducers = combineReducers({
    token: tokenReducer,
    refresh: refreshTokenReducer,
    user: userReducer,
});

let mainEnhancer = compose(persistState(['token', 'refresh', 'user']))

export default createStore(rootReducers, {}, mainEnhancer)