import {Col, Container, Row} from "react-bootstrap";

function Features(){
    return(
        <Container>
            <hr/>
            <Row>
                <Col md={7} className={"align-middle"}>
                    <h2 className="featurette-heading fw-normal lh-1">First featurette heading. <span
                        className="text-muted">It’ll blow your mind.</span></h2>
                    <p className="lead">Some great placeholder content for the first featurette here. Imagine some
                        exciting prose here.</p>

                </Col>
                <Col md={5}>
                    <svg className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                         width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img"
                         aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false">
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#eee"/>
                        <text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text>
                    </svg>
                </Col>
            </Row>
        </Container>
    )
}
export default Features;