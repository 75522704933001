import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {Button} from "react-bootstrap";

function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <svg className="bd-placeholder-img" width="100%" height="15rem" xmlns="http://www.w3.org/2000/svg"
                     aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    <rect width="100%" height="100%" fill="#777"/>
                </svg>
                <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Some representative placeholder content for the first slide of the carousel.</p>
                    <Button>Sing up today</Button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <svg className="bd-placeholder-img" width="100%" height="15rem" xmlns="http://www.w3.org/2000/svg"
                     aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    <rect width="100%" height="100%" fill="#777"/>
                </svg>

                <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Some representative placeholder content for the second slide of the carousel.</p>
                    <Button>Learn More</Button>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <svg className="bd-placeholder-img" width="100%" height="15rem" xmlns="http://www.w3.org/2000/svg"
                     aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                    <rect width="100%" height="100%" fill="#777"/>
                </svg>
                <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>Some representative placeholder content for the third slide of this carousel.</p>
                    <Button>
                        Browse gallery
                    </Button>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default ControlledCarousel;