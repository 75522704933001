import {Component} from "react";
import IndexNavbar from "../components/IndexNavbar";
import ControlledCarousel from "../components/IndexCarusel";
import MarketingController from "../components/MarketingController";
import Footer from "../components/Footer";
import Features from "../components/Features";

class Portal extends Component{
    render() {
        return(
            <div>
                <IndexNavbar />
                <ControlledCarousel/>
                <MarketingController/>
                <Features/>
                <Footer/>
            </div>
        )
    }
}
export default Portal;